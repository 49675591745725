<template>
  <div class="wrapper">
    <head-component />
    <section class="block-courses" v-if="courses">
      <div class="course-item" v-for="course in courses" :key="course.id">
        <div class="row align-items-center mb-1">
          <div class="col-auto">
            <div class="course-item__image">
              <img v-if="course.cover" :src="course.cover.contentPath">
              <img v-else src="https://via.placeholder.com/230x130" />
            </div>
          </div>
          <div class="col">
            <h2 class="mb-2"><a href="/">{{course.name }}</a></h2>
            <div class="row mb-3">
              <div class="col-auto">
                <div class="course-item__videos">
                  {{ course.tasks.videos }} {{course.tasks.videos | pluralize('ru', ['видеолекция', 'видеолекции', 'видеолекций'])}}
                </div>
              </div>
              <div class="col-auto">
                <div class="course-item__tasks">
                  {{course.tasks.tasks}} {{course.tasks.tasks | pluralize('ru', ['задание', 'задания', 'заданий'])}}
                </div>
              </div>
            </div>
            <div class="course-item__text">
              {{ course.shortDescription }}
            </div>
          </div>
          <div class="col-auto">
            <div class="course-item__actions">
              <div v-if="false" class="course-item__progress d-flex align-items-center mb-4">
                <div class="course-item__progress-bg flex-grow-1">
                  <div class="course-item__progress-percent" style="width: 48%"></div>
                </div>
                <div class="course-item__percent">48%</div>
              </div>
              <a :href="`/course/${course.id}`" class="btn btn-success">Приступить</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ApiService from "../services/api.service";
import HeadComponent from "../components/HeadComponent";
export default {
  name: "Rki",
  components: {
    HeadComponent
  },
  data() {
    return {
      courses: null
    }
  },
  mounted() {
    ApiService.get('courses', {
      params: {
        isRki: true
      }
    }).then(res => {
      this.courses = res.data
    })
  }
}
</script>

<style scoped>

</style>